import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/apps/faqtory/src/components/analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cookies"] */ "/vercel/path0/apps/faqtory/src/components/cookies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/apps/faqtory/src/components/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/vercel/path0/apps/faqtory/src/lib/providers/posthog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider"] */ "/vercel/path0/apps/faqtory/src/lib/providers/router.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/faqtory/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider"] */ "/vercel/path0/libs/hooks/src/use-sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/libs/ui/src/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/libs/ui/src/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-66855b96-20241106_h2t3dmjiop65frgcscnzublv3y/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\",\"display\":\"swap\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-66855b96-20241106_h2t3dmjiop65frgcscnzublv3y/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Rubik\",\"arguments\":[{\"variable\":\"--font-heading\",\"subsets\":[\"latin\"]}],\"variableName\":\"fontHeading\"}");
